.SearchInput-module_search-input__wrapper_LpdJS__Main {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 320px;
  margin-left: 10px;
  display: flex;
  position: relative;
}

.SearchInput-module_search-input__cards-dropdown_qqxfQ__Main, .SearchInput-module_search-input__modal-wrapper_TTGVl__Main {
  z-index: 3;
  width: 100%;
  position: absolute;
  top: 35px;
}

.SearchInput-module_search-input--no-mobile_WPxw-__Main {
  display: flex;
}

.SearchInput-module_search-input--no-desktop_OuD9Z__Main {
  display: none;
}

@media (max-width: 1024px) {
  .SearchInput-module_search-input__wrapper_LpdJS__Main {
    max-width: 100%;
    padding: 10px;
  }

  .SearchInput-module_search-input__cards-dropdown_qqxfQ__Main, .SearchInput-module_search-input__modal-wrapper_TTGVl__Main {
    top: 45px;
    left: 0;
  }

  .SearchInput-module_search-input--no-mobile_WPxw-__Main {
    display: none;
  }

  .SearchInput-module_search-input--no-desktop_OuD9Z__Main {
    display: flex;
  }
}

@media (max-width: 660px) {
  .SearchInput-module_search-input__wrapper_LpdJS__Main {
    margin: 0 auto;
    padding: 15px 10px;
  }
}

.InfoModal-module_info-modal_oE6E-__Main {
  background-color: var(--bg-block);
  border: 1px solid var(--line-line);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  display: flex;
}

.InfoModal-module_info-modal__label_YnSME__Main {
  color: var(--text-primary);
}

@media (max-width: 660px) {
  .InfoModal-module_info-modal_oE6E-__Main {
    border: none;
    align-items: flex-start;
    height: 100vh;
    padding-top: 45px;
  }
}

.SearchCardsDropdown-module_cards-dropdown-wrapper_9WKsW__Main {
  background-color: var(--bg-block);
  border: 1px solid var(--line-line);
  border-radius: 10px;
  flex-flow: column;
  gap: 15px;
  width: 100%;
  max-height: 570px;
  padding: 10px;
  display: flex;
  overflow-y: auto;
}

.SearchCardsDropdown-module_cards-dropdown-wrapper__search-text_6IhaW__Main {
  color: var(--text-primary);
}

@media (max-width: 660px) {
  .SearchCardsDropdown-module_cards-dropdown-wrapper_9WKsW__Main {
    height: 100%;
    max-height: calc(100vh - 100px);
  }
}

.TagResultCards-module_search-card-container_m-LcJ__Main {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 290px;
  max-height: 60px;
  padding: 5px;
  display: flex;
}

.TagResultCards-module_search-card-container__avatar_8tJUA__Main {
  border-radius: 50%;
}

.TagResultCards-module_search-card-container__text-wrapper_I-qTO__Main {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
}

.TagResultCards-module_search-card-container__text-wrapper--main_cRUOC__Main {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 230px;
  max-width: 230px;
  color: var(--text-primary);
  overflow: hidden;
}

.TagResultCards-module_search-card-container__text-wrapper--subtitle_qwjFR__Main {
  color: var(--text-secondary);
}

.TagResultCards-module_search-card-container__subtitles_a8FlN__Main {
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  width: 100%;
  display: flex;
}

.TagResultCards-module_search-card-container__subtitles--with-gap_4yAsT__Main {
  gap: 10px;
}

.TagResultCards-module_search-card-container__subtitles--divider_9Z-QA__Main {
  background: var(--line-line);
  width: 1px;
  height: 15px;
}

